import Link from "../atoms/link"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const CustomFooter = styled.footer`
  padding: 2rem 1rem 45px;
  text-align: left;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
`

const LinkStyled = styled(Link)`
  display: block;
  margin-bottom: 0.4em;
`

const ColLinks = styled(Col)`
  a:not(.title) {
    margin-bottom: 0.8em;
  }
`

const ColContact = styled(Col)`
  p:not(.title) {
    margin-bottom: 0.1em;
  }
`

const year = new Date().getFullYear();

const Footer = ({ company }) => (
  <CustomFooter>
    <Container fluid>
      <Row>
        <Col xs="12" className="mb-3" style={{fontSize: "0.9em"}}>
          <p>Any other questions? Please <a href="/contact">contact us</a> or email us at <a href="mailto:info@sperefinancial.com">info@sperefinancial.com</a>.</p>
        </Col>
        <ColLinks xs="6" md="auto" className="pr-md-5 mr-md-5 mb-5" style={{fontSize: "0.8em"}}>
          <div>
            <p>Company</p>
            <LinkStyled to="/">Home</LinkStyled>
            <LinkStyled to="/offering">Current Offering</LinkStyled>
            <LinkStyled to="/about">About Spere Financial</LinkStyled>
            <LinkStyled to="/contact">Contact Us</LinkStyled>
          </div>
        </ColLinks>
        <ColContact xs="6" md="auto" className="ml-md-5" style={{fontSize: "0.8em"}}>
          <p className="title">Contact information</p>
          <p>info@sperefinancial.com</p>
          <p>(833) 33-SPERE [(833) 337-7373]</p>
          <p>1811 Shady Oaks Suite #190</p>
          <p>Denton, TX 76205</p>
        </ColContact>
      </Row>
    </Container>

    <Container fluid>
      <Row style={{fontSize: "0.7em"}}>
        <Col xs="auto">
          <Link to="/disclosure" className="mr-3">Disclosures</Link>
          <Link to="/privacy-policy" className="mr-3">Privacy policy</Link>
          <Link to="/terms-of-use">Terms of use</Link>
        </Col>
        <Col className="text-right">
          <p>© {year} Spere Financial</p>
        </Col>
      </Row>
    </Container>
  </CustomFooter>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
